/*@font-face {*/
/*    font-family: Menlo;*/
/*    src: url("../assets/fonts/MenloRegular.ttf");*/
/*}*/

/*.app-container {*/
/*    font-family: Rubik, sans-serif;*/
/*}*/

/*.app-container * {*/
/*    box-sizing: border-box;*/
/*}*/

/*.bold-400 {*/
/*    font-variation-settings: "wght" 400;*/
/*}*/

/*.bold-500 {*/
/*    font-variation-settings: "wght" 500;*/
/*}*/

/*.bold-600 {*/
/*    font-variation-settings: "wght" 600;*/
/*}*/

/*#home-container {*/
/*    align-items: center;*/
/*    min-height: 100vh;*/
/*    background-size: cover;*/
/*}*/

/*.bold-700 {*/
/*    font-variation-settings: "wght" 700;*/
/*}*/

/*.app-container .main-container {*/
/*    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.16);*/
/*    width: min(635px, calc(100% - 24px));*/
/*    border-radius: 16px;*/
/*    margin-block-end: 159px;*/

/*}*/

/*.main-container .success-title {*/
/*    line-height: 1;*/
/*    padding-block: 26px;*/
/*    background-color: #e7ffed;*/
/*    text-align: center;*/
/*    color: #3eb655;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: flex-end;*/
/*    font-size: 20px;*/
/*}*/

/*.success-title img.success-icon {*/
/*    margin-right: 8px;*/
/*}*/

/*.main-container .inner-content {*/
/*    padding-block: 48px;*/
/*    text-align: center;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*}*/

/*.inner-content #user-id {*/
/*    position: relative;*/
/*    padding: 14px 17px;*/
/*    border-image-slice: 1;*/
/*    width: min(430px, calc(100% - 30px));*/
/*    margin-inline: auto;*/
/*    margin-block: 11px 23px;*/
/*    border-radius: 9px;*/
/*    line-height: 1;*/
/*    font-family: Menlo, serif;*/
/*    cursor: text;*/
/*}*/

/*.inner-content #user-id:before {*/
/*    content: "";*/
/*    position: absolute;*/
/*    inset: 0;*/
/*    border-radius: 9px;*/
/*    padding: 2px;*/
/*    background: linear-gradient(90.31deg, #ff9933 0.11%, #ff3f33 99.82%);*/
/*    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);*/
/*    -webkit-mask-composite: xor;*/
/*    mask-composite: exclude;*/
/*}*/

/*.main-container > .top-band,*/
/*.main-container > .bottom-band {*/
/*    border-radius: inherit;*/
/*}*/

/*.main-container .top-band {*/
/*    border-bottom-left-radius: 0;*/
/*    border-bottom-right-radius: 0;*/
/*}*/

/*.main-container .bottom-band {*/
/*    border-top-left-radius: 0;*/
/*    border-top-right-radius: 0;*/
/*}*/

/*.main-container .sessionButton {*/
/*    box-sizing: border-box;*/
/*    background: #ff9933;*/
/*    border: 1px solid #ff8a15;*/
/*    box-shadow: 0px 3px 6px rgba(255, 153, 51, 0.16);*/
/*    border-radius: 6px;*/
/*    font-size: 16px;*/
/*}*/

/*.bottom-cta-container {*/
/*    display: flex;*/
/*    justify-content: flex-end;*/
/*    padding-inline: 21px;*/
/*    background-color: #212d4f;*/
/*}*/

/*.bottom-cta-container .view-code {*/
/*    padding-block: 11px;*/
/*    color: #bac9f5;*/
/*    cursor: pointer;*/
/*    font-size: 14px;*/
/*}*/

/*.bottom-links-container {*/
/*    display: grid;*/
/*    grid-template-columns: repeat(4, auto);*/
/*    margin-bottom: 22px;*/
/*}*/

/*.bottom-links-container .link {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    margin-inline-end: 68px;*/
/*    cursor: pointer;*/
/*}*/

/*.bottom-links-container .link:last-child {*/
/*    margin-right: 0;*/
/*}*/

/*.truncate {*/
/*    text-overflow: ellipsis;*/
/*    overflow: hidden;*/
/*    white-space: nowrap;*/
/*}*/

/*.separator-line {*/
/*    max-width: 100%;*/
/*}*/

/*.link .link-icon {*/
/*    width: 15px;*/
/*    margin-right: 5px;*/
/*}*/

/*@media screen and (max-width: 768px) {*/
/*    .bottom-links-container {*/
/*        grid-template-columns: repeat(2, 1fr);*/
/*        column-gap: 64px;*/
/*        row-gap: 34px;*/
/*    }*/

/*    .bottom-links-container .link {*/
/*        margin-inline-end: 0;*/
/*    }*/

/*    .separator-line {*/
/*        max-width: 200px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 480px) {*/
/*    #home-container {*/
/*        justify-content: start;*/
/*        padding-block-start: 25px;*/
/*    }*/

/*    .app-container .main-container {*/
/*        margin-block-end: 90px;*/
/*    }*/
/*}*/


/*.parallax {*/
/*  overflow: hidden;*/
/*  letter-spacing: -2px;*/
/*  line-height: 0.8;*/
/*  margin: 0;*/
/*  white-space: nowrap;*/
/*  display: flex;*/
/*  flex-wrap: nowrap;*/
/*}*/

/*.parallax .scroller {*/
/*  font-weight: 600;*/
/*  text-transform: uppercase;*/
/*  font-size: 64px;*/
/*  display: flex;*/
/*  white-space: nowrap;*/
/*  display: flex;*/
/*  flex-wrap: nowrap;*/
/*}*/

/*.parallax span {*/
/*  display: block;*/
/*  margin-right: 30px;*/
/*  background: linear-gradient(*/
/*    rgba(2, 0, 36, 1) 0%,*/
/*    rgba(44, 101, 102, 1) 35%,*/
/*    rgba(0, 212, 255, 1) 100%*/
/*  );*/
/*  -webkit-background-clip: text;*/
/*  -webkit-text-fill-color: transparent;*/
/*}*/

/*.pagefour {*/
/*  font-size: 64px;*/
/*  !*width: 100vw;*!*/
/*  height: 230px;*/
/*  position: relative;*/
/*  !*margin: 50px;*!*/
/*  !*top: 200px;*!*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  background: rgb(2, 0, 36);*/
/*  !*background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(44,101,102,1) 35%, rgba(0,212,255,1) 100%);*!*/
/*  border-radius: 20px;*/
/*  box-shadow: 0 0 1px hsl(0deg 0% 0% / 0.075), 0 0 2px hsl(0deg 0% 0% / 0.075),*/
/*    0 0 4px hsl(0deg 0% 0% / 0.075), 0 0 8px hsl(0deg 0% 0% / 0.075),*/
/*    0 0 16px hsl(0deg 0% 0% / 0.075);*/
/*  transform-origin: 10% 60%;*/
/*}*/

/*h1,*/
/*h2,*/
/*h3 {*/
/*  font-family: sofia-pro, sans-serif;*/
/*  font-weight: 600;*/
/*  font-style: normal;*/
/*}*/

/*h1 {*/
/*  font-size: 36px;*/
/*  font-weight: 700;*/
/*  letter-spacing: -1px;*/
/*  line-height: 1.2;*/
/*  text-align: center;*/
/*  margin: 100px 0 40px;*/
/*}*/

/*h2 {*/
/*  font-weight: 400;*/
/*  margin: 50px 0 10px;*/
/*}*/

/*p {*/
/*  margin: 0 0 30px 0;*/
/*  font-size: 18px;*/
/*}*/

/*footer::before {*/
/*  content: "";*/
/*  display: block;*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 0;*/
/*  right: 0;*/
/*  bottom: 0;*/
/*  background-size: 15px 15px;*/
/*  backdrop-filter: brightness(90%) blur(20px);*/
/*  -webkit-backdrop-filter: brightness(90%) blur(20px);*/
/*  -moz-backdrop-filter: brightness(90%) blur(20px);*/
/*  mask-image: repeating-radial-gradient(*/
/*    circle at 50% 50% center,*/
/*    var(--background),*/
/*    var(--background) 20px,*/
/*    rgba(255, 255, 255, 0.3) 20.5px,*/
/*    rgba(255, 255, 255, 0.3) 21px*/
/*  );*/
/*  -webkit-mask-image: repeating-radial-gradient(*/
/*    circle at 50% 50%,*/
/*    var(--background),*/
/*    var(--background) 20px,*/
/*    rgba(255, 255, 255, 0.3) 20.5px,*/
/*    rgba(255, 255, 255, 0.3) 21px*/
/*  );*/
/*}*/



/*code {*/
/*  font-family: input-mono, monospace;*/
/*  font-weight: 400;*/
/*  font-style: normal;*/
/*}*/

/*#progress {*/
/*  position: fixed;*/
/*  top: 20px;*/
/*  left: 20px;*/
/*  transform: rotate(-90deg);*/
/*}*/

/*circle {*/
/*  stroke-dashoffset: 0;*/
/*  stroke-width: 15%;*/
/*  fill: none;*/
/*}*/

/*.bg {*/
/*  stroke: var(--accent);*/
/*  opacity: 0.3;*/
/*}*/

/*#progress .indicator {*/
/*  stroke: var(--accent);*/
/*}*/

/*ul {*/
/*  display: flex;*/
/*  list-style: none;*/
/*  height: 300px;*/
/*  padding: 20px 0;*/
/*  flex: 0 0 600px;*/
/*  margin: 0 auto;*/
/*}*/

/*::-webkit-scrollbar {*/
/*  height: 5px;*/
/*  width: 5px;*/
/*  background: #fff3;*/
/*  -webkit-border-radius: 1ex;*/
/*}*/

/*::-webkit-scrollbar-thumb {*/
/*  background: var(--accent);*/
/*  -webkit-border-radius: 1ex;*/
/*}*/

/*::-webkit-scrollbar-corner {*/
/*  background: #fff3;*/
/*}*/

/*!*li {*!*/
/*!*  flex: 0 0 200px;*!*/
/*!*  background: var(--accent);*!*/
/*!*  margin: 0 20px 0 0;*!*/
/*!*}*!*/

/*!*li:last-of-type {*!*/
/*!*  margin: 0;*!*/
/*!*}*!*/

/*.refresh {*/
/*  padding: 5px;*/
/*  position: absolute;*/
/*  border: 1px dotted var(--accent);*/
/*  border-radius: 5px;*/
/*  width: 20px;*/
/*  height: 20px;*/
/*  top: 10px;*/
/*  right: 10px;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  cursor: pointer;*/
/*}*/

/*.refresh path {*/
/*  fill: var(--accent);*/
/*}*/

/*.box {*/
/*  width: 200px;*/
/*  height: 200px;*/
/*  background: green;*/
/*}*/
