@tailwind base;
@tailwind components;
@tailwind utilities;


/*.App {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    width: 100vw;*/
/*    height: 100vh;*/
/*    font-family: Rubik;*/
/*}*/

.fill {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}

.sessionButton {
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: black;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 17px;
}

/*:root {*/
/*  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;*/
/*  line-height: 1.5;*/
/*  font-weight: 400;*/

/*  color-scheme: light dark;*/
/*  background-color: white;*/

/*  font-synthesis: none;*/
/*  text-rendering: optimizeLegibility;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  -webkit-text-size-adjust: 100%;*/
/*}*/

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

/*body {*/
/*  --black: #000000;*/
/*  --ash-black: #222;*/
/*  --white: #fafafa;*/
/*  --sky: #00ccff;*/
/*  --green: #22dddd;*/
/*  --blue: #1300ff;*/
/*  --dusk: #6600ff;*/
/*  --purple: #9900ff;*/
/*  --pink: #ff0066;*/
/*  --red: #fe0222;*/
/*  --orange: #fd7702;*/
/*  --yellow: #ffbb00;*/


/*  --accent: var(--white);*/

/*  margin: 0;*/
/*  padding: 0;*/
/*  color: var(--accent);*/
/*  padding-bottom: 100px;*/
/*  overflow: scroll;*/
/*  place-items: center;*/
/*  min-width: 320px;*/
/*  min-height: 100vh;*/
/*}*/

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

/*body {*/
/*  width: 100vw;*/
/*  height: 100vh;*/
/*  background: linear-gradient(180deg, #0055ff 0%, rgb(0, 153, 255) 100%);*/
/*  overflow: hidden;*/
/*  padding: 0;*/
/*  margin: 0;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

nav {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  /*background: #fff;*/
}

button {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  /*position: absolute;*/
  top: 18px;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
}

ul,
li {
  margin: 0;
  padding: 0;
}


li {
  list-style: none;
  height: 35px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  flex: 1;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#page-container {
  color:black;
}

/*footer {*/
/*  position: fixed;*/
/*  display: flex;*/
/*  overflow: hidden;*/
/*  height: 100px;*/
/*  bottom: 0;*/
/*  left: 0;*/
/*  right: 0;*/
/*  justify-content: flex-start;*/
/*  align-items: center;*/
/*  padding: 5px;*/
/*  font-size: 14px;*/
/*  line-height: 14px;*/
/*}*/

/*footer svg {*/
/*  margin-right: 20px;*/
/*  position: relative;*/
/*}*/

/*footer a {*/
/*  text-decoration: none;*/
/*  color: var(--accent);*/
/*  position: relative;*/
/*}*/
